import { Link as ReactRouterLink } from 'react-router-dom';
import { Link } from '@aws-amplify/ui-react'; 
import './ProjectCard.scss';


export default function ProjectCard(props) {
  return(
    <Link to={"/projects/" + props.id} as={ReactRouterLink} >
      <div className='card-container'>
	<figure className="card">
	  <img
	    className="card-image image"
	    src={window.location.origin + "/img/projects/" + props.titleImage}
	    alt={props.caption}
	  />
	  <figcaption className="caption">
	    <h3 className="caption-title">{props.caption}</h3>
	  </figcaption>
	</figure>
	<h1>{props.title}</h1>
	<p>{props.caption}</p>
	<p>Technologies: {props.primaryTags.join(', ')}</p>	
      </div>
    </Link>
  )
};
