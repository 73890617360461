import './Footer.scss';

export default function Footer() {
  let dateObj = new Date().getFullYear();
  return (
    <div className='footer-grid' >
      <div className='footer-general footer-bumper-left' />
      <div className='footer-general footer-main'>
	<p className="text-footer">
          {"\u00A9"} {dateObj} Kane Cullimore
        </p>
      </div>
      <div className='footer-general footer-bumper-right' />    
    </div>    
  );
}
