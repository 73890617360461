import { Menu, MenuItem, Flex } from '@aws-amplify/ui-react'; 
import { Link } from 'react-router-dom';
// import { Link as ReactRouterLink } from 'react-router-dom';
// import { Link } from '@aws-amplify/ui-react'; 
import './NavBar.scss';

export default function NavBar() {
  return (
    <div className='navbar-grid'>
      <div className='navbar-general navbar-bumper-left'/>
      <div className='navbar-general navbar-main nav-flex'>
	<div className='logo-div'>
	  <Link to='/' className='text-nav-title'>
	    <img src='./img/kc_logo_sm.svg' className='logo' alt='logo'/>
	    Kane Cullimore
	  </Link>
	</div>
	<div className='list-right'>
	  <div className='nav-links'>
	    <Link
	      to='/projects'
	      className='nav-link text-nav'	    
	    >Projects</Link>	    
	    <Link
	      to='/about'
	      className='nav-link text-nav'	    
	    >About</Link>
	    <Link
	      to='/'
	      className='nav-link text-nav'
	    >Home</Link>	    
	  </div>
	  <div className='nav-menu'>
	    <Flex direction='column' width='3rem'>
	      <Menu size='large'>
		<Link
		  to='/projects'
		><MenuItem
		   isFullWidth={true}
		   style={{'border-radius': '7px 7px 0px 0px'}}		  
		 >Projects</MenuItem></Link>		
		<Link
		  to='/about'
		  className='to-link'
		><MenuItem isFullWidth={true}>About</MenuItem></Link>
		<Link
		  to='/'
		  className='to-link'	  
		><MenuItem
		   isFullWidth={true}
		   style={{'border-radius': '0px 0px 7px 7px'}}		  		
		 >Home</MenuItem></Link>
	      </Menu>	      
	    </Flex>
	  </div>
	</div>
      </div>
      <div className='navbar-general navbar-bumper-right' />
      <div className='navbar-hr'/>
    </div>    
  );
}
