const projectList = [
  {title: "Quantiful Dashboard",
   id: "001",
   primaryTags: ["R"],
   tags: ["R", "Shiny"],   
   images: ["project001_img01.jpg"],
   titleImage: "project001_img01.jpg",
   caption: "Project 1...."
  },
  {title: "Predictive Running",
   id: "002",
   primaryTags: ["R"],
   tags: ["R", "IoT"],   
   images: ["project002_img01.jpg", "project002_img02.jpg"],
   titleImage: "project002_img01.jpg",
   caption: "Project 2...."
  },
  {title: "Usage Metrics - ADSS",
   id: "003",
   primaryTags: ["Python", "SQL"],
   tags: ["Python", "Data Studio", "AWS", "SQL", "Postgres"],   
   images: ["project003_img01.jpg", "project003_img02.jpg"],
   titleImage: "project003_img01.jpg",
   caption: "Project 3...."
  },
  {title: "Product Analytics - ADSS",
   id: "004",
   primaryTags: ["Python", "R"],
   tags: ["Python", "Elastic Stack", "R"],
   images: ["project004_img01.jpg", "project004_img02.jpg"],
   titleImage: "project004_img01.jpg",
   caption: "Project 4...."
  },
  {title: "Network Graph Visualization",
   id: "005",
   primaryTags: ["JavaScript", "D3"],
   tags: ["JavaScript", "D3"],   
   images: ["project005_img01.jpg"],
   titleImage: "project005_img01.jpg",
   caption: "Project 5...."
  },
  {title: "Layout Engine",
   id: "006",
   primaryTags: ["R", "JavaScript", "NodeJS"],
   tags: ["R", "JavaScript", "NodeJS", "Docker"],
   images: ["project006_img01.jpg", "project006_img02.jpg"],
   titleImage: "project006_img01.jpg",
   caption: "Project 6...."
  },
  {title: "Data Cleaning",
   id: "007",
   primaryTags: ["Python", "D3"],
   tags: ["Python", "D3"],   
   images: ["project007_img01.jpg", "project007_img02.jpg"],
   titleImage: "project007_img01.jpg",
   caption: "Project 7...."
  },
  {title: "Project 8",
   id: "008",
   primaryTags: ["R", "D3", "SQL"],
   tags: ["R", "D3", "SQL"],   
   images: ["project008_img01.jpg", "project008_img02.jpg"],
   titleImage: "project008_img01.jpg",
   caption: "Project 8...."
  }
];

export default projectList;
