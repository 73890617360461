import { useParams } from 'react-router';
import loadable from '@loadable/component';
import './ProjectContent.scss';

export default function ProjectContent() {
  const { id } = useParams();
  const project = 'projects/Project' + id
  console.log(project)
  const AsyncProject = loadable(() => import(`./${project}`))  
  return (
      <AsyncProject />
  )
}
