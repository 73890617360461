import { useState } from 'react'; 
import Select from 'react-select';
import ProjectCard from './ProjectCard';
import './Projects.scss';


export default function Projects(props) {
  const [activeProjects, setActiveProjects] = useState(props.projects)
  const tags = [... new Set(props.projects.map(project => project.primaryTags).flat())]
  const options = []
  tags.forEach(element => {
    options.push({
      value : element.toLowerCase(),
      label : element,
    })
  })
  const onchangeSelect = (tagArray) => {
    let selectTags = tagArray.map((tag) => tag.label)
    let tmpProjects = props.projects.filter((project) => 
      selectTags.every((tag) => project.tags.includes(tag)))
    setActiveProjects(tmpProjects)
  };
 
  return (
    <div>
      <div>
	<h3>Filter Projects by Technology</h3>
	<Select
	  options={options}
	  isMulti={true}
	  onChange={onchangeSelect}
	  className="project-select"
	/>
      </div>
      <div>
	<h3>Projects</h3>      
	<div className="projects">
	  {activeProjects.map(project => (
            <ProjectCard
	      key={project.id}
              id={project.id}
              image={project.thumbnail}
	      title={project.title}
              caption={project.caption}
	      titleImage={project.titleImage}
	      primaryTags={project.primaryTags}
	      tags={project.tags}	    
            />
	  ))}
	</div>
      </div>
    </div>
  )
}
