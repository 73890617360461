import { withAuthenticator } from '@aws-amplify/ui-react';
import Amplify from 'aws-amplify';
// Get the aws resources configuration parameters
import awsconfig from './aws-exports'; // if you are using Amplify CLI
Amplify.configure(awsconfig);
import NavBar from './components/NavBar';
import ContentContainer from './components/ContentContainer';
import Footer from './components/Footer';
import './App.scss';


function App() {
  return (
    <div className="main-grid">
      <NavBar />
      <ContentContainer />
      <Footer />
    </div>
  );
}

export default withAuthenticator(App);

