import { useState } from 'react';
import HomeContent from './HomeContent';
import AboutContent from './AboutContent';
import Projects from './Projects';
import ProjectContent from './ProjectContent'
import { Route, Routes } from 'react-router-dom';
import './ContentContainer.scss';
// Temp project list from local directory
import projectList from '../projectList'


export default function ContentContainer() {
  const [projects, setProjects] = useState(projectList);
  return (
    <div className='content-grid'>
      <div className='content-general content-bumper-left' />
      <div className='content-general content-main'>
	<Routes>
  	  <Route exact path='/' element={<HomeContent />} />
	  <Route path='about' element={<AboutContent />} />
	  <Route path='projects' element={
	    <Projects
	      projects={projects}
	      setProjects={setProjects}/>} />
          <Route path='projects/:id' element={<ProjectContent /> } />
	</Routes>    		  
      </div>
      <div className='content-general content-bumper-right' />
    </div>
  );
}



