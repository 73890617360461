const theme = {
  name: 'my-theme',
  tokens: {
    colors: {
      font: {
      },
    },
  },
};

export default theme;
