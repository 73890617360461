export default function HomeContent() {
  return (
    <div>
      <h1>Home Content</h1>
      <div className="text-wrapper">
	<div className="text">
	  Lorem ipsum odor amet, consectetuer adipiscing elit. Primis eros nunc fringilla id rutrum nibh.
	  Orci convallis pulvinar urna fusce at purus neque nam leo? Suspendisse semper facilisi
	  parturient sit euismod placerat. Orci ante luctus praesent torquent orci commodo aptent blandit.
	  Placerat arcu dui potenti; nullam taciti taciti amet.
	</div>
	<div className="text">
	  Ridiculus proin etiam justo vivamus dignissim suscipit maecenas. Gravida ornare interdum ex dui
	  eu faucibus dictum dis blandit. Rhoncus habitasse suscipit felis massa, ultrices auctor. Laoreet
	  magnis justo velit vulputate iaculis at pulvinar augue. Condimentum suspendisse habitasse metus
	  cubilia curabitur non sem. Primis nam in nulla phasellus bibendum pretium.
	</div>
	<div className="text">
	  Augue malesuada massa torquent diam tortor; porttitor dis massa. Habitasse nunc ad placerat;
	  ante netus gravida a porttitor. Vel aliquet hendrerit efficitur facilisis fames lacinia porta
	  per. Integer euismod aenean mi hendrerit in volutpat consequat tempus turpis. Bibendum massa ad
	  tincidunt, platea montes ac arcu. Penatibus elit justo adipiscing magna vulputate leo per.
	</div>
      </div>
    </div>
  )
}
